<template>
  <div>
    <div class="tw-flex tw-flex-row tw-items-start tw-justify-start">
      <UserAvatar :user="user" size="40" class="tw-mr-4" />
      <div
        class="tw-flex tw-flex-row tw-items-center tw-justify-start tw-border tw-border-solid tw-border-dark-green tw-rounded-3xl tw-flex-1"
      >
        <textarea
          rows="1"
          ref="commentsTextArea"
          @focus="toggleSendButtonOn"
          @blur="toggleSendButtonOff"
          @keydown="onKeyDown"
          @keyup="setTextareaHeight"
          @enter="postComment"
          v-model.trim="comment.text"
          class="tw-text-xs tw-resize-none tw-ml-4 tw-my-2 tw-mr-2 tw-outline-none tw-w-10/12 hide-scrollbar"
        ></textarea>
        <button
          @click="postComment"
          :class="sendButtonVisible ? 'tw-opacity-100' : 'tw-opacity-0'"
          ref="submitButton"
          class="tw-transition-opacity tw-duration-100"
        >
          <img
            src="@/assets/svgs/paper-airplane.svg"
            alt="Send"
            class="tw-w-5 tw-mr-3"
          />
        </button>
      </div>
    </div>
    <div
      :class="isCommentLengthValid ? 'tw-text-charcoal' : 'tw-text-j-red'"
      class="tw-text-xxs tw-mt-2 tw-text-right"
    >
      {{ comment.text.length }} / {{ maxCharLength }}
    </div>
  </div>
</template>
<script>
import UserAvatar from "@/components/shared/UserAvatar.vue";
export default {
  components: {
    UserAvatar
  },
  props: {
    item: {
      type: Object
    },
    user: {
      type: Object
    }
  },
  data() {
    return {
      comment: {
        postUUID: this.item.uuid,
        tripId: this.item.tripId,
        authorId: this.user.id,
        text: ""
      },
      sendButtonVisible: false,
      maxCharLength: 1000
    };
  },
  watch: {
    "comment.text": function (newVal) {
      const btn = this.$refs.submitButton;
      if (newVal.length > this.maxCharLength) {
        btn.classList.remove("tw-opacity-100");
        btn.classList.add("tw-pointer-events-none", "tw-opacity-50");
      } else {
        btn.classList.add("tw-opacity-100");
        btn.classList.remove("tw-pointer-events-none", "tw-opacity-50");
      }
    }
  },
  computed: {
    isCommentLengthValid() {
      return this.comment.text.length <= this.maxCharLength;
    }
  },
  methods: {
    onKeyDown(e) {
      if (e.keyCode === 13) {
        if (e.shiftKey) return;
        e.preventDefault();
        this.postComment();
        this.$refs.commentsTextArea.blur();
      }
    },
    setTextareaHeight(e) {
      this.autoExpand(e.target);
    },
    resetTextareaHeight() {
      const element = this.$refs.commentsTextArea;
      if (element) element.style.height = "20px";
    },
    autoExpand(element) {
      element.style.height = "20px";
      if (this.simple) return;
      const computed = window.getComputedStyle(element);

      const height =
        parseInt(computed.getPropertyValue("border-top-width")) +
        parseInt(computed.getPropertyValue("padding-top")) +
        element.scrollHeight +
        parseInt(computed.getPropertyValue("padding-bottom")) +
        parseInt(computed.getPropertyValue("border-bottom-width"));

      element.style.height = height + "px";
    },
    async postComment() {
      try {
        if (this.comment.text) {
          await this.$store.dispatch("post-comment/create", this.comment);
          this.$emit("comment-posted");
        }
        setTimeout(() => {
          this.resetTextareaHeight();
          this.comment.text = "";
          this.sendButtonVisible = false;
        }, 250);
      } catch (error) {
        console.error("Error posting a comment:", error);
      }
    },
    toggleSendButtonOn() {
      this.sendButtonVisible = true;
    },
    toggleSendButtonOff() {
      if (this.comment.text === "") this.sendButtonVisible = false;
    }
  },
  mounted() {
    this.resetTextareaHeight();
  }
};
</script>
<style lang="scss" scoped>
.hide-scrollbar::-webkit-scrollbar {
  @apply tw-hidden;
}
</style>
