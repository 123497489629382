<template>
  <v-avatar size="size">
    <!-- <v-img class="tw-w-14 tw-h-14" v-if="user.avatarUrl" :src="user.avatarUrl" /> -->
    <div
      :style="avatarColor"
      :class="[
        avatarClass,
        'tw-flex tw-flex-row tw-items-center tw-justify-center'
      ]"
    >
      <span :class="textClass" class="tw-text-white">{{ user.initials }}</span>
    </div>
  </v-avatar>
</template>

<script>
export default {
  name: "UserAvatar",
  props: ["user", "size"],
  computed: {
    textClass() {
      switch (this.size) {
        case "24":
          return "tw-text-sm";
        case "40":
          return "tw-text-sm";
        case "46":
          return "tw-text-sm";
        case "56":
          return "tw-text-2xl"; // Adjust as needed for 56px avatar
        case "124":
          return "tw-text-4xl"; // Adjust as needed for 124px avatar
        default:
          return "tw-text-3xl"; // Default text size
      }
    },
    avatarClass() {
      switch (this.size) {
        case "24":
          return "tw-w-6 tw-h-6";
        case "40":
          return "tw-w-10 tw-h-10";
        case "46":
          return "tw-w-11 tw-h-11";
        case "56":
          return "tw-w-14 tw-h-14";
        case "124":
          return "tw-w-32 tw-h-32";
        default:
          return "tw-w-24 tw-h-24";
      }
    },
    avatarColor() {
      return `background-color: ${this.user.avatarColor}`;
    }
  }
};
</script>
