<template>
  <v-dialog v-model="isVisible" max-width="400">
    <v-card>
      <v-card-title
        class="tw-font-bebas-neue tw-text-uppercase tw-text-charcoal"
        >Delete Post?</v-card-title
      >
      <v-card-text class="tw-text-base"
        >Are you sure you want to continue?</v-card-text
      >
      <v-card-actions class="tw-justify-end">
        <button
          class="btn-tertiary tw-mx-4 tw-tracking-normal"
          @click="$emit('cancel')"
        >
          Cancel
        </button>
        <v-btn
          rounded
          color="primary"
          class="tw-px-4 tw-tracking-normal"
          @click="deleteAccommodation"
          >Delete</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    isVisible: Boolean,
    uuid: String,
  },
  data() {
    return {};
  },
  methods: {
    async deleteAccommodation() {
      await this.$store.dispatch("post-accommodation/remove", this.uuid);
      this.$emit("close");
    },
  },
};
</script>
