<template>
  <div
    class="tw-flex tw-flex-row tw-items-start tw-justify-start tw-text-left tw-text-xs tw-mb-7"
  >
    <UserAvatar :user="comment.user" size="46" class="tw-mr-4" />
    <div class="tw-flex-1">
      <div class="tw-bg-gray-100 tw-rounded-md tw-text-charcoal tw-p-2">
        <p class="tw-mb-0 tw-font-semibold">
          {{ comment.user.firstName }} {{ comment.user.lastName }}
        </p>
        <textarea
          rows="1"
          ref="editTextArea"
          @keyup="setTextareaHeight"
          v-model.trim="comment.text"
          class="tw-text-xs tw-resize-none tw-ml-4 tw-my-2 tw-mr-2 tw-outline-none tw-w-10/12 hide-scrollbar"
          v-show="isEditing"
        ></textarea>
        <p
          class="tw-mb-0 tw-break-all"
          v-html="parseText(comment.text)"
          v-show="!isEditing"
        ></p>
      </div>
      <div
        class="tw-flex tw-flex-row tw-justify-start tw-items-center tw-mt-2 tw-text-light-grey"
        v-if="!isEditing"
      >
        <span class="tw-mr-3">{{
          getFormattedTimeAgo(comment.createdAt)
        }}</span>

        <div>
          <button
            v-if="checkIfUserLiked(comment)"
            @click="unlikeComment(comment)"
          >
            Unlike
          </button>
          <button v-else @click="likeComment(comment)">Like</button>
        </div>
        <v-menu v-if="isCommentOwner">
          <template v-slot:activator="{ on }">
            <v-icon class="tw-ml-3 tw-text-warm-grey" v-on="on"
              >mdi-dots-horizontal</v-icon
            >
          </template>
          <v-list class="tw-text-xs">
            <v-list-item
              class="tw-cursor-pointer"
              @click="editComment(comment)"
            >
              <div>
                <v-icon size="16" class="tw-mr-2" color="#333333"
                  >mdi-pencil</v-icon
                >
                Edit
              </div>
            </v-list-item>
            <v-list-item
              class="tw-cursor-pointer"
              @click="deleteComment(comment)"
            >
              <v-icon size="16" class="tw-mr-2" color="#333333"
                >mdi-delete</v-icon
              >Delete
            </v-list-item>
          </v-list>
        </v-menu>
        <span
          class="tw-ml-auto tw-flex tw-flex-row tw-items-center tw-justify-start"
          v-if="comment.likes.length > 0"
        >
          <span class="tw-mr-1 tw-font-semibold tw-text-charcoal">{{
            comment.likes.length
          }}</span
          ><span>👍</span></span
        >
      </div>
      <div
        class="tw-w-full tw-flex tw-mt-2 tw-items-center tw-justify-end"
        v-else
      >
        <v-btn
          depressed
          color="tertiary"
          class="tw-text-xxs tw-mr-2"
          small
          @click="cancelEditing()"
        >
          Cancel
        </v-btn>
        <v-btn
          depressed
          color="primary"
          small
          :disabled="!isCommentLengthValid"
          class="tw-text-xxs tw-bg-light-grey"
          @click="patchComment"
        >
          Update
        </v-btn>
        <div
          :class="isCommentLengthValid ? 'tw-text-charcoal' : 'tw-text-j-red'"
          class="tw-text-xxs tw-ml-2 tw-text-right"
        >
          {{ comment.text.length }} / {{ maxCharLength }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UserAvatar from "@/components/shared/UserAvatar.vue";
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";

export default {
  components: {
    UserAvatar
  },
  computed: {
    isCommentLengthValid() {
      return this.comment.text.length <= this.maxCharLength;
    },
    checkIfUserLiked() {
      return (comment) =>
        comment.likes.some((obj) => obj.authorId === this.userId);
    },
    isCommentOwner() {
      return this.comment.authorId === this.userId;
    }
  },
  mixins: [FormattedDateRange],
  data() {
    return {
      isEditing: false,
      oldText: "",
      maxCharLength: 1000
    };
  },
  methods: {
    parseText(text) {
      const regex = /(\bhttps?:\/\/\S+\b)/gi;
      return text.replace(
        regex,
        '<a href="$1" class="tw-text-dark-green" target="_blank" rel="noopener noreferrer">$1</a>'
      );
    },
    setTextareaHeight(e) {
      this.autoExpand(e.target);
    },
    resetTextareaHeight() {
      const element = this.$refs.editTextArea;
      if (element) element.style.height = "20px";
    },
    autoExpand(element) {
      this.$nextTick(() => {
        element.style.height = "20px";
        const computed = window.getComputedStyle(element);

        const height =
          parseInt(computed.getPropertyValue("border-top-width")) +
          parseInt(computed.getPropertyValue("padding-top")) +
          element.scrollHeight +
          parseInt(computed.getPropertyValue("padding-bottom")) +
          parseInt(computed.getPropertyValue("border-bottom-width"));

        element.style.height = height + "px";
        this.$refs.editTextArea.focus();
      });
    },
    cancelEditing() {
      this.isEditing = false;
      this.comment.text = this.oldText;
    },
    editComment() {
      this.isEditing = true;
      this.oldText = this.comment.text;
      this.simulateKeyup();
    },
    simulateKeyup() {
      const textarea = this.$refs.editTextArea;
      const event = new Event("keyup");
      textarea.dispatchEvent(event);
    },
    async patchComment() {
      try {
        if (this.comment.text) {
          await this.$store.dispatch("post-comment/patch", [
            this.comment.id,
            this.comment
          ]);
        }
        this.isEditing = false;
        this.oldText = "";
      } catch (error) {
        console.error("Error posting a comment:", error);
      }
    },
    async deleteComment(comment) {
      await this.$store.dispatch("post-comment/remove", comment.id);
      this.$emit("deleted", comment);
    },
    async likeComment(comment) {
      try {
        const payload = {
          commentId: comment.id,
          authorId: this.$store.state.auth.user.id,
          type: "+1"
        };
        const like = await this.$store.dispatch(
          "post-comment-like/create",
          payload
        );
        this.$set(comment.likes, comment.likes.length, like);
      } catch (error) {
        console.error("Error liking a comment:", error);
      }
    },
    async unlikeComment(comment) {
      try {
        const like = comment.likes.find(
          (like) => like.authorId === this.userId
        );
        await this.$store.dispatch("post-comment-like/remove", like.id);
        const likeIndex = comment.likes.indexOf(like);
        this.$delete(comment.likes, likeIndex);
      } catch (error) {
        console.error("Error unliking a comment:", error);
      }
    }
  },
  mounted() {
    this.resetTextareaHeight();
  },
  props: {
    comment: {
      type: Object,
      required: true,
      default: () => {}
    },
    userId: {
      type: Number,
      required: true
    }
  }
};
</script>
