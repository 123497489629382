<template>
  <div
    class="tw-bg-white tw-rounded-md tw-border-b-8 tw-pb-11 tw-border-b-solid tw-border-grey-two"
  >
    <div
      class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-mb-6 tw-pt-6 tw-px-10"
    >
      <div class="tw-flex tw-justify-start tw-items-center">
        <UserAvatar :user="accommodation.user" size="56" class="tw-mr-4" />
        <div class="tw-text-charcoal tw-text-left">
          <div class="tw-text-base tw-font-semibold">
            {{ accommodation.user.firstName }} {{ accommodation.user.lastName }}
          </div>
          <div class="tw-text-xs">
            {{ getFormattedDate(accommodation.createdAt) }}
          </div>
        </div>
      </div>
      <v-menu bottom left v-if="isTripOwner || isPostOwner">
        <template v-slot:activator="{ on }">
          <v-icon
            class="tw-ml-auto tw-self-center tw-text-grey-one"
            v-on="on"
            size="36"
            >mdi-dots-vertical</v-icon
          >
        </template>
        <v-list class="tw-text-xs">
          <v-list-item class="tw-cursor-pointer">
            <router-link
              class="tw-text-charcoal"
              :to="{
                name: 'AccommodationEdit',
                params: { id: accommodation.tripId, uuid: accommodation.uuid }
              }"
            >
              <v-icon size="16" class="tw-mr-2" color="#333333"
                >mdi-pencil</v-icon
              >
              Edit</router-link
            >
          </v-list-item>
          <v-list-item
            class="tw-cursor-pointer"
            @click="$emit('delete', accommodation.uuid)"
          >
            <v-icon size="16" class="tw-mr-2" color="#333333">mdi-delete</v-icon
            >Delete
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <div class="tw-text-left">
      <p class="tw-text-charcoal tw-font-normal tw-px-10">
        {{ accommodation.text }}
      </p>
      <div class="tw-flex tw-flex-col">
        <img
          v-if="!accommodation.imageUrl"
          src="@/assets/svgs/accommodation/post-placeholder.svg"
          class="tw-object-cover tw-h-44"
          alt="Accommodation"
        />
        <img
          v-if="accommodation.imageUrl"
          :src="accommodation.imageUrl"
          class="tw-object-cover tw-h-44"
          alt="Accommodation"
        />
        <div class="tw-py-6 tw-px-10">
          <p
            class="tw-text-left tw-font-figtree tw-font-semibold tw-text-charcoal tw-mb-1 tw-w-11/12 tw-truncate"
          >
            {{ accommodation.name }}
          </p>
          <p
            class="tw-text-left tw-font-figtree tw-text-dark-green tw-text-13 tw-text-ellipsis tw-w-11/12 tw-truncate"
          >
            {{ accommodation.location }}
          </p>
          <p
            class="tw-text-left tw-font-figtree tw-text-dark-green tw-font-semibold tw-text-13 tw-mb-2"
          >
            <span>Price: </span> ${{ accommodation.price / 100 }}
            {{
              accommodation.priceType === price_types.TOTAL
                ? "total"
                : "per night"
            }}
          </p>
          <a
            class="tw-underline tw-text-13 tw-text-link-blue tw-cursor-pointer"
            :href="getHrefLink(accommodation.linkUrl)"
            target="_blank"
            rel="noopener noreferrer"
            v-if="accommodation.linkUrl"
            >View details</a
          >
        </div>
      </div>
    </div>
    <div
      class="tw-border-t tw-border-b tw-border-solid tw-border-lightest-grey tw-py-3 tw-mx-10 tw-flex tw-flex-row tw-justify-between tw-text-xxs tw-text-charcoal"
    >
      <button
        :disabled="reactionButtonDisabled"
        class="j-reaction-pill"
        @click="postReaction('UP', accommodation)"
        :class="{
          'tw-bg-chartreuse': checkIfUserReacted(accommodation, 'UP'),
          'tw-bg-lightest-grey':
            (!checkIfUserReacted(accommodation, 'UP') &&
              checkIfUserReacted(accommodation)) ||
            !checkIfUserReacted(accommodation)
        }"
      >
        <span v-if="upCount > 0" class="tw-font-bold">{{ this.upCount }}</span>
        My fave <span v-if="checkIfUserReacted(accommodation, 'UP')">❤️</span
        ><span v-else>💛</span>
      </button>
      <button
        :disabled="reactionButtonDisabled"
        class="j-reaction-pill"
        @click="postReaction('NEUTRAL', accommodation)"
        :class="{
          'tw-bg-dark-green tw-text-white': checkIfUserReacted(
            accommodation,
            'NEUTRAL'
          ),
          'tw-bg-lightest-grey':
            (!checkIfUserReacted(accommodation, 'NEUTRAL') &&
              checkIfUserReacted(accommodation)) ||
            !checkIfUserReacted(accommodation)
        }"
      >
        <span v-if="neutralCount > 0" class="tw-font-bold">{{
          this.neutralCount
        }}</span>
        I'm down 👍
      </button>
      <button
        :disabled="reactionButtonDisabled"
        class="j-reaction-pill"
        @click="postReaction('DOWN', accommodation)"
        :class="{
          'tw-bg-dark-green tw-text-white': checkIfUserReacted(
            accommodation,
            'DOWN'
          ),
          'tw-bg-lightest-grey':
            (!checkIfUserReacted(accommodation, 'DOWN') &&
              checkIfUserReacted(accommodation)) ||
            !checkIfUserReacted(accommodation)
        }"
      >
        <span v-if="downCount > 0" class="tw-font-bold">{{
          this.downCount
        }}</span>
        Ehhh 😅
      </button>
    </div>
    <div
      class="tw-py-3 tw-flex tw-flex-row tw-justify-end tw-text-xxs tw-text-charcoal tw-px-10"
    >
      <span
        class="tw-cursor-pointer tw-underline"
        @click="toggleDisplayAllComments"
        >{{ accommodation.post_comments.length }} comments</span
      >
    </div>
    <div class="tw-px-10">
      <Comment
        v-for="comment in displayedComments"
        :key="comment.id"
        :comment="comment"
        :userId="user.id"
        @deleted="deletedComment"
      />
      <CommentBuilder
        :user="user"
        :item="accommodation"
        @comment-posted="updateAccommodation"
      />
    </div>
  </div>
</template>
<script>
import { PRICE_TYPES } from "@/enums/price-types.js";
import UserAvatar from "@/components/shared/UserAvatar.vue";
import Comment from "@/components/accommodation/Comment.vue";
import CommentBuilder from "@/components/shared/CommentBuilder.vue";
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
import { UrlHelpers } from "@/mixins/UrlHelpers.js";
import { mapActions } from "vuex";

export default {
  components: {
    UserAvatar,
    Comment,
    CommentBuilder
  },
  mixins: [FormattedDateRange, UrlHelpers],
  data() {
    return {
      comment: {
        postUUID: this.accommodation.uuid,
        tripId: this.accommodation.tripId,
        authorId: this.user.id,
        text: ""
      },
      reaction: {
        postUUID: this.accommodation.uuid,
        authorId: this.user.id,
        type: ""
      },
      editedComment: null,
      sendButtonVisible: false,
      displayAllComments: false,
      reactionButtonDisabled: false,
      price_types: PRICE_TYPES
    };
  },
  props: {
    accommodation: {
      type: Object
    },
    user: {
      type: Object
    },
    isTripOwner: {
      type: Boolean
    }
  },
  computed: {
    upCount() {
      return this.getCountByType("UP");
    },
    neutralCount() {
      return this.getCountByType("NEUTRAL");
    },
    downCount() {
      return this.getCountByType("DOWN");
    },
    displayedComments() {
      if (this.displayAllComments) {
        return this.accommodation.post_comments;
      } else {
        // Sort comments by createdAt in descending order
        const sortedComments = [...this.accommodation.post_comments].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        // Take the top two comments
        return sortedComments.slice(0, 2);
      }
    },
    isPostOwner() {
      return this.accommodation.authorId === this.user.id;
    }
  },
  methods: {
    ...mapActions({
      getAccommodation: "post-accommodation/find"
    }),
    async updateAccommodation() {
      this.editedComment = null;
      const updatedAccommodation = await this.getAccommodation({
        query: {
          uuid: this.accommodation.uuid
        }
      });
      this.$emit("accommodation-updated", updatedAccommodation.data[0]);
    },
    toggleDisplayAllComments() {
      this.displayAllComments = !this.displayAllComments;
    },
    async postReaction(value, accommodation) {
      const userId = this.user.id;
      const userReacted = accommodation.post_likes.some(
        (obj) => obj.authorId === userId
      );
      const sameReaction = accommodation.post_likes.some(
        (obj) => obj.authorId === userId && obj.type === value
      );

      try {
        if (sameReaction) {
          await this.removeExistingReaction(accommodation, userId, value);
          return;
        }

        if (userReacted) {
          await this.removePreviousReaction(accommodation, userId);
        }

        await this.createOrUpdateReaction(accommodation, value);
      } catch (error) {
        console.error("Error posting a reaction:", error);
      }
    },
    async removeExistingReaction(accommodation, userId, value) {
      this.reactionButtonDisabled = true;
      const existingReaction = accommodation.post_likes.find(
        (obj) => obj.authorId === userId && obj.type === value
      );
      if (existingReaction) {
        await this.$store.dispatch("post-like/remove", existingReaction.id);
        this.$delete(
          accommodation.post_likes,
          accommodation.post_likes.indexOf(existingReaction)
        );
      }
      this.reactionButtonDisabled = false;
    },
    async removePreviousReaction(accommodation, userId) {
      this.reactionButtonDisabled = true;
      const previousReaction = accommodation.post_likes.find(
        (obj) => obj.authorId === userId
      );
      if (previousReaction) {
        await this.$store.dispatch("post-like/remove", previousReaction.id);
        this.$delete(
          accommodation.post_likes,
          accommodation.post_likes.indexOf(previousReaction)
        );
      }
      this.reactionButtonDisabled = false;
    },
    async createOrUpdateReaction(accommodation, value) {
      this.reactionButtonDisabled = true;
      this.reaction.type = value;
      const like = await this.$store.dispatch(
        "post-like/create",
        this.reaction
      );
      this.$set(
        accommodation.post_likes,
        accommodation.post_likes.length,
        like
      );
      this.reactionButtonDisabled = false;
    },
    checkIfUserReacted(accommodation, reaction) {
      // Check if the user has reacted to the specified accommodation with a specific type
      if (reaction) {
        return accommodation.post_likes.some(
          (obj) => obj.authorId === this.user.id && obj.type === reaction
        );
      } else {
        // Check if the user has reacted with any type for the specified accommodation
        return accommodation.post_likes.some(
          (obj) => obj.authorId === this.user.id
        );
      }
    },
    getCountByType(type) {
      return this.accommodation.post_likes.filter((obj) => obj.type === type)
        .length;
    },
    toggleSendButtonOn() {
      this.sendButtonVisible = true;
    },
    toggleSendButtonOff() {
      if (this.comment.text === "") this.sendButtonVisible = false;
    },
    deletedComment(comment) {
      this.accommodation.post_comments =
        this.accommodation.post_comments.filter((c) => c.id !== comment.id);
    }
  }
};
</script>
<style lang="scss">
.j-reaction-pill {
  @apply tw-rounded-3xl tw-py-2 tw-px-5;
}
</style>
